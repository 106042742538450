<app-button
  uid="auth-signin-with-apple"
  mixpanelEvent="auth-signin-with-apple"
  appleAuth
  [mode]="mode"
  color="black"
  label="{{ (isSignupMode() ? 'auth-continue-with-apple' : 'auth-login-with-apple') | translate }}"
  image="assets/img/apple-logo-white.svg"
  class="logo__color"
  (signin)="onSignin($event)"
/>
