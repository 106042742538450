import { inject, Injectable } from '@angular/core'
import { _errorDataAppend, _filterNullishValues, AppError, pTry } from '@naturalcycles/js-lib'
import {
  ActivationChannel,
  AppId,
  BackendResponseFM,
  ErrorCode,
  Goal,
  HardwareId,
  LoginOrRegisterInput,
  normalizeLanguage,
  productKeyByHwId,
  RecaptchaAction,
  WebSignupQueryParam,
} from '@naturalcycles/shared'
import { MixpanelService } from '@src/app/core/services/analytics/mixpanel.service'
import { cookieService } from '@src/app/core/services/cookie.service'
import { userDeviceService } from '@src/app/core/services/user-device.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { sentry } from '@src/app/core/util/sentry.util'
import { OptimoveEvent } from '@src/app/shared/typings/analytics'
import { AuthMode, AuthProviderType } from '@src/app/shared/typings/enum/auth'
import { productKeyToRegistrationFlow } from '@src/app/shared/typings/interfaces/products'
import {
  AuthConsentInput,
  AuthProviderResult,
  EmailAuthProviderResult,
  SocialAuthProviderResult,
} from '@src/app/shared/typings/interfaces/user-auth'
import { isSignupMode } from '../util/authMode.util'
import { AccountService } from './account.service'
import { optimoveWebSdk, RegistrationData } from './analytics/optimove.service'
import { api } from './api.service'
import { CartService } from './cart.service'
import { RecaptchaService } from './recaptcha.service'
import { sessionSigningService } from './sessionSigning.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private store = inject(SignalStore)
  private accountService = inject(AccountService)
  private cartService = inject(CartService)
  private recaptchaService = inject(RecaptchaService)
  private mixpanelService = inject(MixpanelService)

  async createOrLogin(result: AuthProviderResult): Promise<BackendResponseFM | undefined> {
    switch (result.type) {
      case AuthProviderType.email: {
        return await this.createOrLoginWithEmail(result as EmailAuthProviderResult)
      }
      case AuthProviderType.social: {
        return await this.createOrLoginWithSocialAuth(result as SocialAuthProviderResult)
      }
      default: {
        throw new Error(`Unknown auth provider type: ${result.type}`)
      }
    }
  }

  private async createOrLoginWithEmail(
    result: EmailAuthProviderResult,
  ): Promise<BackendResponseFM> {
    const userDevice = userDeviceService.getUserDeviceInput()
    if (result.mode === AuthMode.login) {
      return await this.accountService.login({
        email: result.input.email,
        pw: result.input.pw,
        userDevice,
        publicKey: await sessionSigningService.getPublicKey(),
      })
    }

    const recaptchaToken = await this.recaptchaService.generateAndStoreRecaptchaToken(
      RecaptchaAction.register,
    )

    const { quiz, lang, cart, account, discountCode } = this.store.getState()
    // If the user can select a monthly plan and opt out of the therm
    // We currently set the hwId to T1 even though the user is using their own thermometer
    cart.hwId ||= HardwareId.ORAL_THERMOMETER

    const hwIdAsProduct = productKeyByHwId[cart.hwId]
    const regFlow = productKeyToRegistrationFlow[hwIdAsProduct]

    const hasClearblueCodeApplied = this.cartService.hasClearblueCodeApplied(cart)

    const body: LoginOrRegisterInput = {
      email: result.input.email,
      pw: result.input.pw,
      recaptchaToken,
      ...result.consent,
      plan: this.cartService.getSubscriptionType(),
      lang: normalizeLanguage(lang),
      userDevice,
      goal: account.goal,
      irclickid: cookieService.getCookie(WebSignupQueryParam.irclickid),
      hwId: cart.hwId,
      regFlow: Number(regFlow || '') || undefined,
      quizData: quiz.data || undefined,
      publicKey: await sessionSigningService.getPublicKey(),
      ...(isSignupMode(result.mode) && {
        appId: hasClearblueCodeApplied ? AppId.CLEARBLUE : AppId.NC,
        activationChannel: hasClearblueCodeApplied
          ? ActivationChannel.CLEARBLUE
          : ActivationChannel.NC,
      }),
      discountCode: discountCode?.code,
    }

    const response = await this.accountService.createOrLogin(_filterNullishValues(body))
    if (!result.input.accountAlreadyExists) {
      void this.processPostRegistrationSideEffects()
    }

    return response
  }

  private async createOrLoginWithSocialAuth(
    result: SocialAuthProviderResult,
  ): Promise<BackendResponseFM | undefined> {
    const { goal } = this.store.$account()
    const [errorSigningIn, response] = await pTry(this.accountService.socialAuth(result))
    if (!errorSigningIn) {
      if (!response?.account?.completeDate) {
        await this.patchAccountIfConsented(result.consent, goal)
      }
      if (!result.input.accountAlreadyExists) {
        void this.processPostRegistrationSideEffects()
      }
      return response
    }

    if (
      errorSigningIn instanceof AppError &&
      errorSigningIn.cause?.data.code === ErrorCode.ACCOUNT_EXISTS
    ) {
      const password = await this.accountService.linkAccountWithPassword(
        result.input.loginProvider,
        result.input.email,
      )
      result.input.pw = password
      const [errorLinking, response] = await pTry(this.accountService.socialAuth(result))
      if (errorLinking) {
        await this.accountService.errorLinkingAccount(errorLinking, result.input.email)
        return
      }
      if (!response?.account?.completeDate) {
        await this.patchAccountIfConsented(result.consent, goal)
      }
      return response
    }

    throw errorSigningIn
  }

  private async patchAccountIfConsented(consent?: AuthConsentInput, goal?: Goal): Promise<void> {
    if (!consent) return
    try {
      await this.accountService.patch({
        ...consent,
        goal,
      })
    } catch (err) {
      sentry.captureException(_errorDataAppend(err, { fingerprint: 'patchAccountIfConsented' }))
    }
  }

  private async processPostRegistrationSideEffects(): Promise<void> {
    void this.logRegistrationInOptimove()
    void this.accountService.sendVerificationEmail()
  }

  private async logRegistrationInOptimove(): Promise<void> {
    const { marketingConsent, regCountry, personalId, email, goal } = this.store.$account()
    const { hardware } = this.store.getState()
    const plan = this.cartService.getSubscriptionType() // need to get from cart because plan is not set at the registration.

    await optimoveWebSdk.registerUser(personalId, email)
    // next calls HAVE to be after registerUser is called

    const hwIdAsProduct = productKeyByHwId[hardware.hwId]
    const regFlow = productKeyToRegistrationFlow[hwIdAsProduct]

    const params = {
      viewType: 'Website', // according to `getViewType` implementation in NCBackend3
      plan,
      marketingConsent,
      regCountry,
      regFlow: Number(regFlow || '') || undefined,
      ...(hardware.hwId === HardwareId.OURA && { ownsOuraRing: hardware.ownsDevice }),
      ...(hardware.hwId === HardwareId.APPLE_WATCH && { ownsAppleWatch: hardware.ownsDevice }),
      // other "generic properties" are not known at this point yet
      // appVersion: undefined,
    }

    await Promise.all([
      optimoveWebSdk.reportEvent(OptimoveEvent.RegistrationStart, params),
      plan && // Undefined for AB_282
        api.put(`optimove/event/choosePlan`, {
          json: {
            plan,
          },
        }),
    ])

    const registrationData: RegistrationData = {
      plan,
      hwId: HardwareId[hardware.hwId],
      goal: Goal[goal!],
      ownsHw: hardware.ownsDevice,
    }

    /**
     * As per CRM request, we should make sure RegistrationData gets fired AFTER RegistrationStart
     * That is why this request is not included in Promise.all() above
     */
    await optimoveWebSdk.reportEvent(OptimoveEvent.RegistrationData, registrationData)
  }
}
