import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { EmailInput } from '@naturalcycles/shared'
import { TextInputComponentModule } from '@src/app/components/input/text/text-input.component.module'
import { emailValidator } from '@src/app/core/util/validators.util'
import { SharedModule } from '@src/app/shared/shared.module'

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, SharedModule, TextInputComponentModule],
  template: `
    <div class="forms__formGroup">
      <app-text-input
        id="email"
        type="email"
        name="email"
        [label]="'reg-label-email' | translate"
        [formControl]="form.controls['email']!"
        sensitive
        required="true"
      />
    </div>
  `,
})
export class VerifyEmailComponent implements OnInit {
  api!: EmailInput

  form = new FormGroup({
    email: new FormControl('', [Validators.required, emailValidator()]),
  })

  ngOnInit(): void {
    this.form.controls['email'].setValue(this.api.email)
  }
}
