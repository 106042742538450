import { Component, inject } from '@angular/core'
import { isChromeBrowser, LoginProvider } from '@naturalcycles/shared'
import { Decorate } from '@src/app/core/decorators/decorators'
import { AccountService } from '@src/app/core/services/account.service'
import { AuthMode, AuthProviderType } from '@src/app/shared/typings/enum/auth'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import {
  GoogleAuthUser,
  SocialAuthProviderInput,
} from '@src/app/shared/typings/interfaces/user-auth'
import { LoaderType } from '@src/app/shared/typings/ui'
import { AuthProviderComponent } from '../auth-provider.component'

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: [],
})
export class GoogleAuthComponent extends AuthProviderComponent {
  private accountService = inject(AccountService)
  public isChromeBrowser = isChromeBrowser()
  public AuthMode = AuthMode
  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async onSignin(user: GoogleAuthUser): Promise<void> {
    this.didChangeProvider.emit(AuthProviderType.social)

    const accountAlreadyExists = await this.accountService.shouldLoginUser({
      provider: LoginProvider.GOOGLE,
      token: user.authentication.idToken,
      email: user.email,
    })

    const input: SocialAuthProviderInput = {
      type: AuthProviderType.social,
      accountAlreadyExists,
      id: user.id,
      token: user.authentication.idToken,
      email: user.email,
      name: user?.givenName,
      lastName: user?.familyName,
      allowToCreateNewAccount: this.isSignupMode(),
      loginProvider: LoginProvider.GOOGLE,
    }

    this.authResult.emit({
      mode: this.mode,
      type: input.type,
      input,
    })
  }
}
