import { Component, ViewEncapsulation } from '@angular/core'

@Component({
  template: '<div [innerHTML]="api.content | md"></div>',
  styleUrls: ['./consent-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsentInfoModalComponent {
  api!: { content: string }
}
