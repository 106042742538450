import { Component, inject, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AppError } from '@naturalcycles/js-lib'
import { LoginProvider } from '@naturalcycles/shared'
import { Decorate } from '@src/app/core/decorators/decorators'
import { AccountService } from '@src/app/core/services/account.service'
import { AuthMode, AuthProviderType } from '@src/app/shared/typings/enum/auth'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import {
  AppleAuthUser,
  SocialAuthProviderInput,
} from '@src/app/shared/typings/interfaces/user-auth'
import { LoaderType } from '@src/app/shared/typings/ui'
import { AuthProviderComponent } from '../auth-provider.component'

@Component({
  selector: 'app-apple-auth',
  templateUrl: './apple-auth.component.html',
  styleUrls: [],
})
export class AppleAuthComponent extends AuthProviderComponent implements OnInit {
  private activatedRouter = inject(ActivatedRoute)
  private accountService = inject(AccountService)
  public AuthMode = AuthMode

  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async ngOnInit(): Promise<void> {
    const base64Token = this.activatedRouter.snapshot.queryParams['appleAuth']
    if (!base64Token) {
      return
    }
    if (base64Token === 'failed') {
      throw new AppError('Sign in With Apple Failed')
    }

    this.didChangeProvider.emit(AuthProviderType.social)
    const response = JSON.parse(atob(base64Token)) as AppleAuthUser
    return await this.onSignin(response)
  }

  public async onSignin(user: AppleAuthUser): Promise<void> {
    const accountAlreadyExists = await this.accountService.shouldLoginUser({
      provider: LoginProvider.APPLE,
      token: user.token,
    })
    const input: SocialAuthProviderInput = {
      type: AuthProviderType.social,
      accountAlreadyExists,
      id: user.id,
      token: user.token,
      email: user.email,
      allowToCreateNewAccount: this.isSignupMode(),
      loginProvider: LoginProvider.APPLE,
    }

    this.authResult.emit({
      mode: this.mode,
      type: input.type,
      input,
    })
  }
}
