import { Component, inject, OnDestroy, OnInit, output } from '@angular/core'
import { ConsentInfoModalComponent } from '@src/app/components/modals/consent-info-modal/consent-info-modal.component'
import { api } from '@src/app/core/services/api.service'
import { ModalService } from '@src/app/core/services/modal.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { AcceptConsentEvent } from '../../shared/typings/interfaces/events'

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit, OnDestroy {
  store = inject(SignalStore)
  private modalService = inject(ModalService)

  hideGoToBottomContainer = true

  $terms = this.store.$consent

  toggleMap = {
    marketing: true,
    socialMarketing: true,
  }

  accept = output<AcceptConsentEvent>()
  decline = output<void>()

  ngOnInit(): void {
    this.checkViewportStage = this.checkViewportStage.bind(this)

    window.addEventListener('scroll', this.checkViewportStage)
    setTimeout(this.checkViewportStage, 100)
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.checkViewportStage)
  }

  checkViewportStage(): void {
    const consentButtons = document.querySelector('.consent__buttons')
    if (consentButtons) {
      const bounding = consentButtons.getBoundingClientRect()

      if (bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        this.hideGoToBottomContainer = true
      } else {
        this.hideGoToBottomContainer = false
      }
    }
  }

  onLanguageChange(lang: string): void {
    void this.fetchTerms(lang)
  }

  async fetchTerms(lang: string): Promise<void> {
    await api.get('consent/terms', { searchParams: { lang } })
  }

  onClickItemInfo(info: string): void {
    void this.modalService.show({
      component: ConsentInfoModalComponent,
      modalName: 'ConsentInfoModal',
      api: {
        content: info,
      },
    })
  }

  onClickItemToggle(key: 'marketing' | 'socialMarketing' | string): void {
    this.toggleMap[key as 'marketing' | 'socialMarketing'] =
      !this.toggleMap[key as 'marketing' | 'socialMarketing']
  }

  goToButtonComponent(): void {
    /* The buttons are at the bottom of the container, but just using scrollIntoView({ block: 'end' }) 
    on them behaves a bit weirdly, so we just go to the end of the container instead. */
    const container = document.querySelector('.container')
    container?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  async acceptClick(): Promise<void> {
    this.accept.emit({
      marketingConsent: this.toggleMap.marketing,
      marketingSocialConsent: this.toggleMap.socialMarketing,
      consentTermsLanguage: this.store.$consent().language,
    })
  }

  declineClick(): void {
    this.decline.emit()
  }
}
