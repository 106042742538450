import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { emailValidator } from '@src/app/core/util/validators.util'
import { SharedModule } from '@src/app/shared/shared.module'
import { TextInputComponentModule } from '../../input/text/text-input.component.module'

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, SharedModule, TextInputComponentModule],
  template: `
    <div>
      <p>{{ 'reset-password-modal-body' | translate }}</p>
      <div style="position: relative">
        <app-text-input
          id="email"
          type="email"
          name="email"
          placeholder="email"
          (input)="onPasswordInput($event.target.value)"
          [label]="'form-label-email' | translate"
          [formControl]="form.controls['email']!"
        />
      </div>
    </div>
  `,
  styleUrls: ['../../../../scss/_input.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  api!: {
    email?: string
  }

  form = new FormGroup({
    email: new FormControl('', [Validators.required, emailValidator()]),
  })

  ngOnInit(): void {
    this.form.controls.email.setValue(this.api?.email || null)
  }

  onPasswordInput(pw: string): void {
    this.form.controls.email.setValue(pw)
    this.api.email = pw
  }
}
