<app-button
  uid="auth-signin-with-google"
  mixpanelEvent="auth-signin-with-google"
  googleAuth
  text="black"
  color="white"
  border="black"
  label="{{
    (isSignupMode() ? 'auth-continue-with-google' : 'auth-login-with-google') | translate
  }}"
  image="assets/img/google.svg"
  (signin)="onSignin($event)"
>
  @if (isChromeBrowser) {
    <div id="legacy-g-signin" class="legacy-g-signin__button"></div>
  }
</app-button>
